import { lazy } from "react";
import PreliminaryStaffingOverview from "../screens/preliminarystaffing/viewproject";
import { PageConfig } from "../configure/PathConfig";

const LazyHome = lazy(() => import("../screens/dashboard"));
const LazyAllDealReview = lazy(() =>
	import("../screens/dealreview/allviewdeals/AllDealReview")
);
const LazyDrDashboard = lazy(() =>
	import("../screens/dealreview/dealreviewDashboard/index")
);
const LazyAllLegalReview = lazy(() =>
	import("../screens/legal/overview/AllLegalReview")
);
const LazyLegalQuestionnaire = lazy(() =>
	import("../screens/legal/LegalQuestionnaire/LegalQuestionnaire")
);
const LazyOverviewNewDeal = lazy(() =>
	import("../screens/dealreview/droverview")
);
const LazyLegDashboard = lazy(() =>
	import("../screens/legal/NewLegalDashboard")
);
const LazyLegalOverview = lazy(() => import("../screens/legal/legaloverview"));
const LazyDealReviewStart = lazy(() =>
	import("../screens/dealreview/dealreviewstart")
);
const LazySOWGenAndUpload = lazy(() => import("../screens/legal/uploadsow"));
const LazySOWGenReviewMain = lazy(() =>
	import("../screens/legal/CustomerReview")
);
const LazyGenerateSOW = lazy(() => import("../screens/legal/generatesow"));
const LazyReviewAndApproval = lazy(() =>
	import("../screens/dealreview/reviewandapproval")
);
const LazyRMPeopleLanding = lazy(() =>
	import("../screens/RM/people/peoplelanding")
);
const LazyResourceAllocation = lazy(() =>
	import("../screens/RM/resourceallocation/resourceallocation")
);
const LazyProjectsDashboard = lazy(() => import("../screens/RM/projects"));
const LazyProjectHome = lazy(() =>
	import("../screens/RM/resourceallocation/projecthome")
);
const LazyRMDashboard = lazy(() => import("../screens/RM/dashboard"));
const LazyProfile = lazy(() => import("../screens/RM/people/peopleprofile"));

const LazyResourceAllocationFact = lazy(() =>
	import("../screens/RM/resourceallocation/resourceallocationfact")
);
const LazyResourceAllocationReview = lazy(() =>
	import("../screens/RM/resourceallocation/resourceallocationreview")
);
const LazyPreliminaryStaffingReview = lazy(() =>
	import("../screens/preliminarystaffing/preliminationstaffingreview")
);

const LazyPreliminaryStaffingDashboard = lazy(() =>
	import("../screens/preliminarystaffing/dashboard")
);
const LazyINABDashboard = lazy(() => import("../screens/INAB/dashboard"));
const LazyINABReviewAndApproval = lazy(() => import("../screens/INAB/reviewandapproval"));
const LazyINABNewSubmission = lazy(() => import("../screens/INAB/reviewandapproval"));
const LazyINABSubmitForRm = lazy(() => import("../screens/INAB/reviewandapproval"));

const LazyProjectsOverview = lazy(() =>
	import("../screens/RM/projects/projectsoverview")
);
const LazyINABRMProjectsOverview = lazy(() =>
	import("../screens/RM/projects/inaboveriew")
);
const LazyRMAddEmployee = lazy(() =>
	import("../screens/RM/AddEmployee")
);
const LazyAdminDashboard = lazy(() =>
	import("../screens/admin/dashboard")
);
const LazySettings = lazy(() =>
	import("../screens/settings/notification")
);
const LazyAddAccessPolicy = lazy(() =>
	import("../screens/admin/createAccessPolicy")
);
const LazyAdminRBAC = lazy(() =>
	import("../screens/admin/RBAC")
);
const LazyAdminRBACRateCard = lazy(() =>
	import("../screens/admin/RateCard")
);

export const privateRoutes = [
	{
		id: 1,
		path: `${PageConfig.DASHBOARD}/:currentTab`,
		component: LazyHome,
		access: ["READ", "user"],
	},
	{
		id: 2,
		path: `${PageConfig["DEALS"].DEALREVIEWDASHBOARD}/:currentTab`,
		component: LazyDrDashboard,
		access: ["READ", "user"],
	},
	{
		id: 3,
		path: `/:module${PageConfig["DEALS"].DEALREVIEW}/:dealstatus/:previousTab/:currentTab/:projectid`,
		component: LazyDealReviewStart,
		access: ["READ", "user"],
	},
	{
		id: 4,
		path: `/:module${PageConfig["DEALS"].REVIEWANDAPPROVAL}/:dealstatus/:projectid`,
		component: LazyReviewAndApproval,
		access: ["READ", "user"],
	},
	{
		id: 5,
		path: "/deal-review-dashboard/all-deal-review",
		component: LazyAllDealReview,
		access: ["READ", "user"],
	},
	{
		id: 6,
		path: `/:module${PageConfig["DEALS"].OVERVIEWPROJECT}/:dealstatus/:previousTab/:currentTab/:projectid`,
		component: LazyOverviewNewDeal,
		access: ["READ", "user"],
	},
	{
		id: 7,
		path: `${PageConfig["LEGAL"].LEGAL_DASHBOARD}/:currentTab`,
		component: LazyLegDashboard,
		access: ["legal_access"],
	},
	{
		id: 8,
		path: `/:module${PageConfig["LEGAL"].OVERVIEWPROJECT}/:dealstatus/:previousTab/:currentTab/:projectid`,
		component: LazyLegalOverview,
		access: ["legal_access"],
	},
	{
		id: 10,
		path: "/legal-dashboard/all-legal-review",
		component: LazyAllLegalReview,
		access: ["legal_access"],
	},
	{
		id: 11,
		path: `/:module${PageConfig["LEGAL"].SOWGENERATE}/:dealstatus/:previousTab/:currentTab/:projectid`,
		component: LazyGenerateSOW,
		access: ["generate_sow"],
	},
	{
		id: 12,
		path: `/:module${PageConfig["LEGAL"].SOWUPLOAD}/:dealstatus/:previousTab/:projectid`,
		component: LazySOWGenAndUpload,
		access: ["generate_sow"],
	},
	{
		id: 13,
		path: `/:module${PageConfig["LEGAL"].FINALREVIEW}/:dealstatus/:previousTab/:projectid`,
		component: LazySOWGenReviewMain,
		access: ["lg_customer_review"],
	},
	{
		id: 14,
		path: `/:module${PageConfig["DEALS"].LEGALQUESTIONNAIRE}/:dealstatus/:dealid`,
		component: LazyLegalQuestionnaire,
		access: ["READ"],
	},
	{
		id: 15,
		path: `${PageConfig["RM"].RMDASHBOARD}`,
		component: LazyRMDashboard,
		access: ["READ"],
	},
	{
		id: 16,
		path: `${PageConfig["RM"].PEOPLELANDING}/:currentTab`,
		component: LazyRMPeopleLanding,
		access: ["READ"],
	},
	{
		id: 16,
		path: `${PageConfig["RM"].PROJECTS}/:currentTab`,
		component: LazyProjectsDashboard,
		access: ["READ"],
	},

	{
		id: 17,
		path: `${PageConfig["RM"].PROJECTHOME}/:currentTab`,
		component: LazyProjectHome,
		access: ["READ"],
	},
	{
		id: 18,
		path: `/:module${PageConfig["RM"].RESOURCEALLOCTION}/:dealstatus/:projectid`,
		component: LazyResourceAllocation,
		access: ["READ"],
	},
	{
		id: 19,
		path: `/:module${PageConfig["RM"].RESOURCEALLOCTIONFACT}/:projectid`,
		component: LazyResourceAllocationFact,
		access: ["READ"],
	},
	{
		id: 20,
		path: `${PageConfig["RM"].PROFILE}/:dealstatus`,
		component: LazyProfile,
		access: ["READ"],
	},
	{
		id: 21,
		path: `/:module${PageConfig["RM"].RESOURCEALLOCTIONFACTREVIEW}/:dealstatus/:projectid`,
		component: LazyResourceAllocationReview,
		access: ["READ"],
	},
	{
		id: 22,
		path: `/:module${PageConfig["PRELIMINARY"].OVERVIEW}/:dealstatus/:projectid`,
		component: PreliminaryStaffingOverview,
		access: ["READ"],
	},
	{
		id: 23,
		path: `/:module${PageConfig["PRELIMINARY"].REVIEW}/:dealstatus/:projectid`,
		component: LazyPreliminaryStaffingReview,
		access: ["READ"],
	},
	{
		id: 24,
		path: `${PageConfig["PRELIMINARY"].DASHBOARD}`,
		component: LazyPreliminaryStaffingDashboard,
		access: ["READ"],
	},
	{
		id: 25,
		path: `${PageConfig["INAB"].DASHBOARD}/:currentTab`,
		component: LazyINABDashboard,
	},
	{
		id: 26,
		path: `${PageConfig["RM"].PRoJECTSOVERVIEW}/:dealstatus/:currentTab/:projectid`,
		component: LazyProjectsOverview,
		access: ["READ","WRITE"],
	},
	{
		id: 27,
		path: `${PageConfig["INAB"].REVIEWANDAPPROVAL}/:dealstatus/:inapnum`,
		component: LazyINABReviewAndApproval,
		access: ["READ"],
	},
	{
		id: 28,
		path: `${PageConfig["INAB"].SUBMITFORRM}/:dealstatus/:inapnum`,
		component: LazyINABSubmitForRm,
		access: ["READ"],
	},
	{
		id: 29,
		path: `${PageConfig["INAB"].NEWSUBMISSION}`,
		component: LazyINABNewSubmission,
		access: ["READ"],
	},
	{
		id: 30,
		path: `${PageConfig["RM"].INABPROJECTSOVERVIEW}/:dealstatus/:inapnum`,
		component: LazyINABRMProjectsOverview,
		access: ["READ","WRITE"],
	},
	{
		id: 31,
		path: `/:module${PageConfig["RM"].INABALLOCATON}/:inapnum`,
		component: LazyINABRMProjectsOverview,
		access: ["READ","WRITE"],
	},
	{
		id: 32,
		path: `/:module${PageConfig["RM"].INABRESOURCEALLOCTIONFACT}/:dealstatus/:inapnum`,
		component: LazyResourceAllocationFact,
		access: ["READ"],
	},
	{
		id: 33,
		path: `/:module${PageConfig["RM"].INABRESOURCEALLOCTIONFACTREVIEW}/:dealstatus/:inapnum`,
		component: LazyResourceAllocationReview,
		access: ["READ"],
	},
	{
		id: 34,
		path: `${PageConfig["RM"].ADDEMPLOYEE}`,
		component: LazyRMAddEmployee,
		access: ["READ"],
	},
	{
		id: 35,
		path: `/:module${PageConfig["RM"].PRELIMINARY}/:dealstatus/:projectid`,
		component: PreliminaryStaffingOverview,
		access: ["READ"],
	},
	{
		id: 36,
		path: "/admin/:currentTab",
		component: LazyAdminDashboard,
		access: ["READ"],
	},
	{
        id: 37,
        path: "/settings/notification",
        component: LazySettings,
        access: ["READ","WRITE"],
    },
	{
        id: 38,
        path: "/admin/accesspolicy",
        component: LazyAddAccessPolicy,
        access: ["READ","WRITE"],
    },
	{
        id: 39,
        path: "/admin/annotation",
        component: LazyAdminRBAC,
        access: ["READ","WRITE"],
    },
	{
        id: 40,
        path: "/admin/ratecard",
        component: LazyAdminRBACRateCard,
        access: ["READ","WRITE"],
    },
];
