// apiConfig.js
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { BASE_URL } from "./exportConfigurtion";

console.log("base url", BASE_URL);

export const DEALREVIEW = `dealreview`;
export const LEGAL = `legal`;
const FORMULA = `formula`;
const RM = `resourcemanagement`;
const PRELIMINARY = `preliminarystaffing`;
const INAP = "inab";
const NOTIFICATION = "notification";
const ADMIN = "admin";

export const API_ROUTES = {
  GET_SUPER_ADMIN_DETAIL: `/${DEALREVIEW}/getadmindashboard`,
  GET_STFRTCT: `/${DEALREVIEW}/drstaffinggetrates`,
  GET_DEALS_FROM_DR_AND_HS: `/${DEALREVIEW}/drgetdashboard`,
  GET_DR_DATA_FOR_OVERVIEW_AND_STAFFING: `/${DEALREVIEW}/drgetsummarydetails`,
  GET_ROLE_FOR_STAFFING: `/${DEALREVIEW}/drstaffinggetrolecard`,
  UPDATE_STAFFING_AND_OVERVIEW: `/${DEALREVIEW}/drsavestaffingoverview`,
  GET_DR_FILTER_DETAILS_FROM_DB: `/${DEALREVIEW}/drgetfilter`,
  GET_NEW_DR_DETAILS_FROM_DB: `/${DEALREVIEW}/drgetoverview`,
  UPDATED_STATUS: `/${DEALREVIEW}/drupdatestatus`,
  UPDATE_NEW_DR: `/${DEALREVIEW}/updatenewdetailstohs`,
  SUBMIT_LEGAL: `/${DEALREVIEW}/legalinsertion`,
  SAVE_ADDITIONAL_INFO: `/${DEALREVIEW}/updateadditionalinfo`,
  GET_LIFECYCLE: `/${DEALREVIEW}/drgetlifecycle`,
  GET_PRICINGTYPE: `/${DEALREVIEW}/drgetpricingtype`,
  GET_SERVICES: `/${DEALREVIEW}/drgetservices`,
  GET_TECHNOLOGY: `/${DEALREVIEW}/gettechnology`,
  GET_STAFFING_ROLECARDS: `/${DEALREVIEW}/drstaffinggetrolecard`,
  INSERT_DEALREVIEW: `/${DEALREVIEW}/dealinsertion`,
  UPDATE_STAFF_ROLE_DATAT: `/${DEALREVIEW}/staffingsheetcalc`,
  UPDATE_CLIENT_MANAGED: `/${DEALREVIEW}/updateclientmanaged`,
  SUBMIT_FOR_APPROVAL: `/${DEALREVIEW}/submitforapproval`,
  GET_DURATION_WEEKS: `/${DEALREVIEW}/durationinweeks`,
  GET_DR_ROLES: `/${DEALREVIEW}/getroles`,
  GET_HUBSPOT_DATA: `/${DEALREVIEW}/gethubspotdata`,
  HUBSPOT_INSERTION:`/${DEALREVIEW}/hubspotinsertion`,

  GET_HOURS: `/${FORMULA}/gethours`, //removed
  GET_WAVICLE_COST: `/${FORMULA}/getwaviclecost`, //removed
  GET_WAVICLE_DISCOUNT_COST: `/${FORMULA}/getwaviclediscount`, //removed
  GET_FIXED_PRICE_ACTUAL_REVENUE: `/${FORMULA}/getfpar`, //removed
  GET_PROPOSED_HOURS: `/${FORMULA}/getproposedhours`, //removed
  GET_CLIENT_STANDARD_PRICE: `/${FORMULA}/getclientstandardprice`, //removed
  GET_REVENUE_AFTER_DEDUCTIONS: `/${FORMULA}/getrad`, //removed
  GET_PL_COST: `/${FORMULA}/getplcost`, //removed
  GET_CL_COST: `/${FORMULA}/getclcost`, //removed
  GET_AIRFARE_AMOUNT: `/${FORMULA}/getairfareamount`, //removed
  GET_HOTEL_AMOUNT: `/${FORMULA}/gethtmoa`, //removed
  GET_TOTAL_TRAVEL_EXPENSES_AMOUNT: `/${FORMULA}/gettotaltravelamount`, //removed
  GET_IN_A_BOX_COST: `/${FORMULA}/getdl2em2bc`, //removed
  GET_REV_BEFORE_DEDUCTIONS_COST: `/${FORMULA}/getdregmbeforedc`, //removed
  GET_REV_BEFORE_DEDUCTIONS_PERCENTAGE: `/${FORMULA}/getdrgmbeforedp`, //removed
  GET_REF_AFTER_DEDUCTIONS_COST: `/${FORMULA}/getdrgmafterdcost`, //removed
  GET_EM2_AND_DL2_IN_BOX_UTILIZATION_PERCENTAGE: `/${FORMULA}/getdl2up`, //removed
  GET_DEAL_REVENUE_AND_GM_AFTER_DEDUCTIONS_PERCENTAGE: `/${FORMULA}/getdrgmafterdp`, //removed
  GET_TOTAL_WAVICLE_COST: `/${FORMULA}/gettotalwaviclecost`, //removed
  GET_TOTAL_CLIENT_PRICE: `/${FORMULA}/gettotalclientprice`, //removed
  GET_TOTAL_ACTUAL_REVENUE: `/${FORMULA}/gettotalactualrevenue`, //removed
  GET_TOTAL_HOURS_PROPOSED: `/${FORMULA}/gettotalhoursproposed`, //removed
  GET_FINAL_GROSS_MARGIN_COST: `/${FORMULA}/getfgmc`, //removed
  GET_FINAL_GROSS_MARGIN_PERCENTAGE: `/${FORMULA}/getfgmp`, //removed
  GET_STAFFING_RATIO_AND_EXPECTED_MARGIN_PERCENTAGE: `/${FORMULA}/getsremp`, //removed
  GET_STAFFING_RATIO_AND_MARGIN_CURRENT_MARGIN_PERCENTAGE: `/${FORMULA}/getrmcmp`, //removed
  GET_STAFFING_RATIO_AND_MARGIN_PERCENTAGE: `/${FORMULA}/getsrmp`, //removed
  GET_MONTHLY_PAYMENT: `/${FORMULA}/getmonthlypayment`, //removed
  GET_DEAL_REVENUE_AND_GM_AFTER_DEDUCTIONS_COST: `/${FORMULA}/getdrgmafterdcost`, //removed

  //legal API Endpoint
  GET_USER_PERMISSION: `/${LEGAL}/getuserrbac`,
  GET_SOW_TEMPLATE: `/${LEGAL}/getsowtemplates`,
  GET_LEGAL_DASHBOARD: `/${LEGAL}/legaldashboard`,
  GET_LEGAL_DEAL_REVIEW: `/${LEGAL}/legaloverview`,
  GET_LEGAL_STAFFING: `/${LEGAL}/getlegalstaffing`,
  UPLOAD_SOW: `/${LEGAL}/sowupload`,
  UPDATE_LEGAL_STATUS: `/${LEGAL}/legalupdatestatus`,
  SOW_GENERATE: `/${LEGAL}/sowgeneration`,
  LEGAL_QUESTIONNAIRE: `/${LEGAL}/legalquestionnaire`,
  REJECT_DEAL: `/${LEGAL}/updatedealstage`,
  GET_LEGAL_QUESTIONNAIRE: `/${LEGAL}/getlegalquestionnaire`,
  INERST_INTO_ACTIVE_PROJECTS: `/${LEGAL}/insertactiveproject`,
  INSERT_LEGAL_TO_RM: `/${LEGAL}/rminsertion`,

  // resource management API Endpoints
  GET_RM_EMPLOYEE_OVERVIEW: `/${RM}/employeeprofile`,
  GET_RM_DASHBOARD_DATA: `/${RM}/rmdashboardcard`,
  GET_RM_PROJECT_DASHBOARD: `/${RM}/getrmprojectdashboard`,
  GET_RM_PROJECT_DASHBOARD_DATA: `/${RM}/getradashboard`,
  GET_INAB_RM_PROJECT_DASHBOARD_DATA: `/${RM}/getallocationreviewinternalproject`,
  UPDATE_STATUS_IN_RM: `/${RM}/rmupdatestatus`,
  GET_ROLECARDS: `/${RM}/getrmrolecard`,
  UPDATE_RESOURCES: `/${RM}/resourceupdate`,
  GET_REVIEW_RESOURCES: `/${RM}/getallocationreview`,
  GET_ROLECARD_RESOURCES: `/${RM}/getrolecardresources`,
  GET_PEOPLE_RM: `/${RM}/getrmpeople`,
  GET_RM_DASHBOARD_RESOURCE_DETAIL_TABLE: `/${RM}/getrmdashboardtable`,
  GET_RM_DASHBOARD_STAT: `/${RM}/getrmstatics`,
  GET_RM_OVERVIEW_DATA: `/${RM}/getrmprojectdetails`,
  GET_RESOURCES_DATA: `/${RM}/getrmcurrentresources`,
  UPDATE_RM_SKILS: `/${RM}/updateskills`,
  GET_SKILLS: `/${RM}/getallskills`,
  UPDATENOTES: `/${RM}/updationnotes`,
  GET_ALL_RESOURCES: `/${RM}/getresources`,
  ADD_EMPLOYEE:`/${RM}/resourceinsertion`,
  UPDATE_ALLOCATION_STATUS:`/${RM}/updateallocationstatus`,
  RM_GET_DIM_DATA: `/${RM}/rmgetdimdata`,
  // preliminary staffing
  GET_ALL_PRELIMINARY_DETAILS: `/${PRELIMINARY}/getpreliminarystaffing`,
  DELETE_PRELIMINARY_STAFFING: `/${PRELIMINARY}/deletepreliminarystaffing`,
  SAVE_PRELIMINARY_STAFFING: `/${PRELIMINARY}/savepreliminarystaffing`,
  UPDATE_STATUS_SCOPED_PROJECT: `/${PRELIMINARY}/scopedupdatestatus`,
  DELETE_ROLE_CARD_DB: `/${PRELIMINARY}/deletestaffingroles`,
  //inap
  GET_INAP_PROJECT: `/${INAP}/getinabproject`,
  CREATE_NEW_SUBMISSION: `/${INAP}/inabnewsubmission`,
  GET_INAB_DASHBOARD_DATA: `/${INAP}/getdashboard`,
  STATUS_UPDATE: `/${INAP}/inabstatusupdate`,
  SUBMIT_TO_RM_FROM_INAB: `/${INAP}/inabsubmitrm`,
  GET_INAB_CAL_VALUES: `/${INAP}/inabcalc`,

  //notification
  GET_NOTIFICATIONS_BY_EMAIL: `/${NOTIFICATION}/getnotifications`,
  UPDATE_NOTIFICATION_STATUS: `/${NOTIFICATION}/notificationstatusupdate`,
  GET_MENU_STRUCTURE_FOR_NOTIFICATION_MENU:`/${NOTIFICATION}/getmenudetailsfornotificationsetting`,
  ACTION_GROUP_CREATION: `/${NOTIFICATION}/actiongroupcreation`, //actiongroupcreation

  RBAC_GET_LIST_ACCESS: `/${ADMIN}/rbacgetlistaccess`,
  RBACINSERTION : `/${ADMIN}/rbacinsertion`,
  RBACDELETION : `/${ADMIN}/rbacdeletion`
};
export const MULTIPART_FORM_HEADERCONFIG = {
  "Content-Type": "multipart/form-data",
};

export const getApiUrl = (route) => `${BASE_URL}${route}`;

const axiosInstance = axios.create();

// export const apiGet = async (route, params, cancelTokenSource = null) => {
// 	try {
// 		const response = await axios.get(getApiUrl(route), {
// 			params,
// 			cancelToken: cancelTokenSource?.token,
// 		});
// 		return response;
// 	} catch (error) {
// 		if (axios.isCancel(error)) {
// 			console.log("Request canceled:", error.message);
// 		} else {
// 			console.error("Error making GET request:", error);
// 		}
// 		throw error;
// 	}
// };
const cancelTokenSources = {}; // Object to hold cancel token sources for different methods

export const apiGet = async (route, params, methodKey = null, header = {}) => {
  try {
    if (!methodKey) {
      const guid = uuidv4();
      methodKey = guid;
    }

    // Cancel previous request for this method, if any
    if (cancelTokenSources[methodKey]) {
      cancelTokenSources[methodKey].cancel(
        "Request canceled because a new request was made"
      );
    }

    // Create a new cancel token source for this method
    cancelTokenSources[methodKey] = axios.CancelToken.source();

    const response = await axiosInstance.get(getApiUrl(route), {
      params,
      headers: header,
      cancelToken: cancelTokenSources[methodKey].token,
    });

    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error making GET request:", error);
    }
    throw error;
  }
};

// export const apiPost = async (
// 	route,
// 	data,
// 	cancelTokenSource = null,
// 	header = {},
// 	onUploadProgress
// ) => {
// 	try {
// 		const response = await axios.post(getApiUrl(route), data, {
// 			cancelToken: cancelTokenSource?.token,
// 			headers: header,
// 			onUploadProgress: onUploadProgress,
// 		});
// 		return response;
// 	} catch (error) {
// 		if (axios.isCancel(error)) {
// 			console.log("Request canceled:", error.message);
// 		} else {
// 			console.error("Error making POST request:", error);
// 		}
// 		throw error;
// 	}
// };

export const apiPost = async (
  route,
  data,
  methodKey = null,
  header = {},
  onUploadProgress
) => {
  try {
    if (!methodKey) {
      const guid = uuidv4();
      methodKey = guid;
    }

    // Cancel previous request for this method, if any
    if (cancelTokenSources[methodKey]) {
      cancelTokenSources[methodKey].cancel(
        "Request canceled because a new request was made"
      );
    }
    // Create a new cancel token source for this method
    cancelTokenSources[methodKey] = axios.CancelToken.source();

    const response = await axiosInstance.post(getApiUrl(route), data, {
      cancelToken: cancelTokenSources[methodKey].token,
      headers: {...header},
      onUploadProgress: onUploadProgress,
    });

    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error making POST request:", error);
    }
    throw error;
  }
};

export const apiPut = async (route, data, cancelTokenSource = null, header = {}) => {
  try {
    const response = await axiosInstance.put(getApiUrl(route), data, {
      cancelToken: cancelTokenSource?.token,
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error making PUT request:", error);
    }
    throw error;
  }
};

export const apiDelete = async (route, data, cancelTokenSource = null,header) => {
  try {
    const response = await axiosInstance.delete(getApiUrl(route), {params: data}, {
      headers: {
        ...header,
      },
      cancelToken: cancelTokenSource?.token,
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error making DELETE request:", error);
    }
    throw error;
  }
};

export const apiPatch = async (route, data, cancelTokenSource = null,header) => {
  try {
    const response = await axiosInstance.patch(getApiUrl(route), data, {
      headers:{
        ...header
      },
      cancelToken: cancelTokenSource?.token,
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error making PATCH request:", error);
    }
    throw error;
  }
};



export default axiosInstance;
